import './styles.css';

import React, { MouseEvent, useState } from 'react';

const ReserveSpot = () => {
    const [isHovering, setIsHovering] = useState<boolean>(false);
    const joinWaitlist = (e: MouseEvent) => {
        e.preventDefault();
        window.open('https://trellistrade.co/sign-up');
    };
    return (
        <div className="footer-background">
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 40
                }}
            >
                <label className="footer-header">Get started today</label>
                <label className="footer-body">
                    Join our community of 500+ traders for free.
                </label>
            </div>
            <div className="footer-button_container">
                <button
                    onClick={joinWaitlist}
                    className="footer-button"
                    onMouseEnter={() => setIsHovering(true)}
                    onMouseLeave={() => setIsHovering(false)}
                    style={{
                        ...(isHovering && {
                            transform: 'scale(1.05)'
                        })
                    }}
                >
                    Get Started
                </button>
            </div>
            <label
                style={{
                    color: 'rgb(90,90,90)',
                    paddingLeft: 20,
                    paddingRight: 20,
                    textAlign: 'center'
                }}
            >
                Have more questions? Email us at{' '}
                <a
                    style={{
                        color: '#8c30f5',
                        cursor: 'pointer',
                        textDecoration: 'none'
                    }}
                    href="mailto:team@trytrellis.co"
                >
                    team@trytrellis.co
                </a>
            </label>
        </div>
    );
};
export default ReserveSpot;
