import React, { useEffect, useState } from 'react';
import { Api } from 'src/api';
import NavBar from 'src/components/NavBar';

import DragDropPreview from './components/DragDropPreview';
import { Leaderboard } from './components/Leaderboard';
import MainBanner from './components/MainBanner';
import ReserveSpot from './components/ReserveSpot';
import SocialIntegrations from './components/SocialIntegrations';
import { SocialMedia } from './components/SocialMedia';
import { TrackPerformance } from './components/TrackPerformance';

const LandingPage = () => {
    const [numDecisionsMade, setNumDecisionsMade] = useState<number>(0);

    const handleFetchNumDecisions = async () => {
        try {
            const numDecisionsRes = await Api.getNumDecisionsMade();
            const { numDecisions } = numDecisionsRes.data;
            if (numDecisions) {
                setNumDecisionsMade(numDecisions);
            }
        } catch {}
    };

    useEffect(() => {
        handleFetchNumDecisions();
    }, []);
    return (
        <>
            <NavBar />
            <MainBanner numDecisionsMade={numDecisionsMade} />
            <DragDropPreview />
            <SocialIntegrations />
            <TrackPerformance />
            <Leaderboard />
            <ReserveSpot />
            <SocialMedia />
        </>
    );
};

export default LandingPage;
