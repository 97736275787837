import React from 'react';
import { CSSProperties } from 'react';
import { isMobile } from 'react-device-detect';
import PerformanceGraphic from '../../../../assets/graphics/performance-screenshot.png';
import './styles.css';

export const TrackPerformance = () => (
    <div style={styles.container}>
        <label style={styles.title}>Track your bot's performance</label>
        <label style={styles.headerDesc}>
            <label style={{ color: '#8c30f5' }}>Compare</label> it to the ticker
            being traded.
        </label>
        <img src={PerformanceGraphic} className="performance-graphic" alt="" />
    </div>
);

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: 100,
        paddingLeft: 20,
        paddingRight: 20
    } as CSSProperties,
    title: {
        fontSize: isMobile ? 33 : 50,
        fontWeight: 800,
        lineHeight: 1.2,
        maxWidth: '90%',
        whiteSpace: 'pre-line' as 'pre-line',
        textAlign: 'center'
    } as CSSProperties,
    headerDesc: {
        fontSize: 23,
        marginTop: 15,
        maxWidth: 650,
        fontWeight: 600,
        textAlign: 'center'
    } as CSSProperties
};
