import React, { CSSProperties } from 'react';
import FacebookIcon from 'src/assets/icons/icons8-facebook-48.png';
import LinkedinIcon from 'src/assets/icons/icons8-linkedin-48.png';
import InstagramIcon from 'src/assets/icons/icons8-instagram-48.png';
import TwitterIcon from 'src/assets/icons/icons8-twitter-48.png';

export const SocialMedia = () => (
    <div style={styles.container}>
        <a
            href="https://www.linkedin.com/company/trellis-trading"
            target="_blank"
        >
            <img src={LinkedinIcon} style={styles.icons} />
        </a>
        <a href="https://www.instagram.com/trytrellis/" target="_blank">
            <img src={InstagramIcon} style={styles.icons} />
        </a>
        <a href="https://twitter.com/trytrellis/" target="_blank">
            <img src={TwitterIcon} style={styles.icons} />
        </a>
        <a href="https://www.facebook.com/trytrellis/" target="_blank">
            <img src={FacebookIcon} style={styles.icons} />
        </a>
    </div>
);

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 50
    } as CSSProperties,
    icons: {
        height: 32,
        width: 32,
        marginLeft: 15,
        marginRight: 15,
        cursor: 'pointer'
    } as CSSProperties
};
