import './styles.css';

import React from 'react';
import TradingBot from 'src/assets/graphics/money-bot.png';
import { isMobile } from 'react-device-detect';

export const RightContent = () => {
    return (
        <div className="background_right-content_container">
            <img src={TradingBot} className="trading-bot-image" alt="" />
        </div>
    );
};
