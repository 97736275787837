import './styles.css';

import React from 'react';

import { LeftContent } from './LeftContent';
import { RightContent } from './RightContent';

type Props = {
    numDecisionsMade: number;
};

const MainBanner = ({ numDecisionsMade }: Props) => {
    return (
        <div className="background">
            <LeftContent numDecisionsMade={numDecisionsMade} />
            <RightContent />
        </div>
    );
};

export default MainBanner;
