import './styles.css';

import React from 'react';
import { isMobile } from 'react-device-detect';
import Phone from 'src/assets/graphics/mobile-notification-mockup.png';
import SocialIcons from 'src/assets/graphics/socials2.png';

const SocialIntegrations = () => {
    return (
        <div className="social-container">
            <div className="social_left-content">
                <div style={styles.sectionContainer}>
                    <label style={styles.headerText}>Connect your bot</label>
                    <label style={styles.headerDesc}>
                        Get notified whenever your bot makes a trade, or have
                        your bot text you for permission before submitting a
                        trade.
                    </label>
                </div>
                <div>
                    <img
                        src={SocialIcons}
                        className="social_left-content_icons"
                        style={styles.socialMediaIcons}
                        alt=""
                    />
                </div>
            </div>
            <img src={Phone} className="social_right-content_image" alt="" />
        </div>
    );
};

const styles = {
    headerText: {
        fontSize: isMobile ? 36 : 50,
        fontWeight: 800,
        lineHeight: 1.2,
        maxWidth: 500,
        color: 'white',
        ...(isMobile && {
            marginLeft: 20,
            marginRight: 20,
            textAlign: 'center' as 'center'
        })
    },
    headerDesc: {
        fontSize: 23,
        marginTop: 15,
        maxWidth: 600,
        fontWeight: 600,
        color: 'white',
        ...(isMobile && {
            marginLeft: 15,
            marginRight: 15
        })
    },
    sectionContainer: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        marginBottom: 30
    },
    socialMediaIcons: {
        ...(isMobile && { marginBottom: 50 })
    }
};

export default SocialIntegrations;
