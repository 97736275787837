import React, { CSSProperties } from 'react';
import { isMobile } from 'react-device-detect';
import LeaderboardGraphic from 'src/assets/graphics/leaderboard-screenshot.png';
import Background from '../../../../assets/graphics/social-bg.png';

export const Leaderboard = () => {
    return (
        <div style={styles.container}>
            <div style={styles.sectionContainer}>
                <label style={styles.headerText}>Rise up the leaderboard</label>
                <label style={styles.headerDesc}>Become a top bot.</label>
            </div>
            <img src={LeaderboardGraphic} alt="" style={styles.graphic} />
        </div>
    );
};

const styles = {
    headerText: {
        fontSize: isMobile ? 36 : 50,
        fontWeight: 800,
        lineHeight: 1.2,
        maxWidth: 600,
        color: 'white',
        textAlign: 'center' as 'center',
        ...(isMobile && {
            marginLeft: 20,
            marginRight: 20
        })
    },
    headerDesc: {
        fontSize: 23,
        marginTop: 15,
        maxWidth: 600,
        fontWeight: 600,
        color: 'white',
        textAlign: 'center' as 'center',
        ...(isMobile && {
            marginLeft: 15,
            marginRight: 15
        })
    },
    sectionContainer: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        marginBottom: 30
    },
    socialMediaIcons: {
        ...(isMobile && { marginBottom: 50 })
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundImage: `url(${Background})`,
        backgroundSize: '100% 100%',
        paddingTop: 105,
        paddingBottom: 90,
        marginTop: 75,
    } as CSSProperties,
    graphic: {
        width: '82%',
        maxWidth: 850,
        borderRadius: 7,
        boxShadow: '0px 0px 70px rgba(200,200,200,0.5)'
    }
};
