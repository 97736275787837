import './styles.css';

import React from 'react';
import { Nav } from 'react-bootstrap';
import TrellisLogo from 'src/assets/logos/trellis_logo.png';
import { isMobile } from 'react-device-detect';

const NavBar = () => {
    return (
        <Nav className="header">
            <Nav.Item className="counterbalance" />
            <Nav.Item className="header_project-logo">
                <img src={TrellisLogo} alt="" />
            </Nav.Item>
            <Nav.Item className="header_project-name">Trellis</Nav.Item>
            <Nav.Item
                onClick={() =>
                    window.open('https://www.trellistrade.co/sign-in')
                }
                className="navbar_login"
            >
                <div
                    className="navbar_login_button_container"
                    style={styles.loginBtnContainer}
                >
                    <button
                        className="navbar_login_button"
                        style={styles.loginBtn}
                    >
                        Sign In
                    </button>
                </div>
            </Nav.Item>
        </Nav>
    );
};

const styles = {
    loginBtnContainer: {
        width: isMobile ? 65 : 80,
        ...(!isMobile && { height: 37 })
    },
    loginBtn: {
        width: '100%',
        height: '100%'
    }
};

export default NavBar;
