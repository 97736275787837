import './styles.css';

import React, { CSSProperties } from 'react';
import { isMobile } from 'react-device-detect';
import MockUp from 'src/assets/graphics/ifthenExample.png';

const DragDropPreview = () => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
                marginTop: 60
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '90%',
                    marginBottom: 25,
                    ...(isMobile && { marginLeft: 30, marginRight: 30 })
                }}
            >
                <label style={styles.headerText}>
                    Write your bot using if-then statements
                </label>
                <label style={styles.headerDesc}>
                    Our programming language is{' '}
                    <label style={{ color: '#8c30f5' }}>plain english.</label>
                </label>
            </div>
            <div>
                <img
                    src={MockUp}
                    className="functionality_trading-bot-image"
                    alt=""
                />
            </div>
        </div>
    );
};

const styles = {
    headerText: {
        fontSize: isMobile ? 33 : 50,
        fontWeight: 800,
        lineHeight: 1.2,
        maxWidth: '90%',
        whiteSpace: 'pre-line' as 'pre-line',
        textAlign: 'center'
    } as CSSProperties,
    headerDesc: {
        fontSize: 23,
        marginTop: 15,
        maxWidth: 600,
        fontWeight: 600,
        marginLeft: 7,
        marginRight: 7,
        textAlign: 'center'
    } as CSSProperties
};

export default DragDropPreview;
