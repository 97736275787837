import './styles.css';

import React, { CSSProperties, MouseEvent, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useWindowWidth } from '@react-hook/window-size';

type Props = {
    numDecisionsMade: number;
};

export const LeftContent = ({ numDecisionsMade }: Props) => {
    const [isHovering, setIsHovering] = useState<boolean>(false);
    const windowWidth = useWindowWidth();
    const joinWaitlist = (e: MouseEvent) => {
        e.preventDefault();
        window.open('https://trellistrade.co/sign-up');
    };

    const numDecisionsMadeFormatted = numDecisionsMade
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return (
        <div className="background_left-content_container">
            <label className="background_left-content_build-tagline">
                Build Trading Bots
            </label>
            <h1 className="background_left-content_no-code-tagline">
                Without Code
            </h1>
            {numDecisionsMade ? (
                <label style={styles.numDecisionsMadeText(windowWidth)}>
                    {numDecisionsMadeFormatted}+ trades made
                </label>
            ) : null}
            <div className="background_left-content_button-container">
                <button
                    onClick={joinWaitlist}
                    className="background_left-content_join-waitlist"
                    onMouseEnter={() => setIsHovering(true)}
                    onMouseLeave={() => setIsHovering(false)}
                    style={{
                        ...(isHovering && {
                            transform: 'scale(1.05)'
                        })
                    }}
                >
                    Get Started
                </button>
            </div>
        </div>
    );
};

const styles = {
    numDecisionsMadeText: (windowWidth: number) =>
        ({
            fontSize: 27,
            fontWeight: 600,
            color: '#00a33c',
            ...(windowWidth < 1000
                ? {
                      textAlign: 'center',
                      marginTop: 15,
                      marginBottom: -5
                  }
                : {
                      marginTop: 20,
                      marginBottom: -10
                  })
        } as CSSProperties)
};
